import React from 'react';
import { observer } from 'mobx-react';
import { ApiPrice } from '@ibe/api';
import Keys from '@/Translations/generated/en/package.json.keys';
import useTranslationMGL from '@/Util/useTranslationMgl';

import { useConfig, Price } from '@ibe/components';
import { useGlobalPriceHooks } from '@/components/offer/Offer';

export interface Props {
  price: ApiPrice;
}

export const PriceListItemLayout = observer((props: Props) => {
  const { t } = useTranslationMGL('package');
  const config = useConfig();
  const { price } = props;
  const { discount, priceWithoutDiscount } = useGlobalPriceHooks(price);

  return (
    <div className="package-info__price float-right">
      <div className="text-right">
        <Price
          className="discount__price"
          prefix="-"
          price={discount}
          roundPrice={config.roundPricesToWholeNumber}
        />
      </div>
      <div className="d-flex justify-content-end align-items-end">
        <span className="from__text">{`${t(Keys.from)}`}</span>
        {priceWithoutDiscount ? (
          <Price
            className="price__without__discount"
            price={priceWithoutDiscount}
            roundPrice={config.roundPricesToWholeNumber}
          />
        ) : null}
        <Price
          displayInline
          price={price}
          className="finale__price font-weight-bold"
          roundPrice={config.roundPricesToWholeNumber}
        />
      </div>
      <div className="text-right">{t(Keys.perAdult)}</div>
    </div>
  );
});
