import { observer } from 'mobx-react';
import React from 'react';
import {
  ApiComponent,
  ApiComponentType,
  ApiInsurance,
  ApiItemType,
  ApiService,
  ApiServiceUnit
} from '@ibe/api';
import { Button } from 'reactstrap';
import { Price } from '@ibe/components';
import useTranslationMGL from '@/Util/useTranslationMgl';
import Keys from '@/Translations/generated/en/Checkout.json.keys';
import { useTheme } from '@/Util/useTheme';
import { sanitize } from '@ibe/services';

export interface OptionalServiceCardProps {
  component: ApiComponent;
  service: ApiService;
  unit: ApiServiceUnit;
  handleRemoveService: (componentId: string, unitId: string) => void;
  handleSelectService: (componentId: string, unitId: string) => void;
}

const OptionalServiceCard = observer(function OptionalServiceCard(props: OptionalServiceCardProps) {
  const { component, service, unit, handleSelectService, handleRemoveService } = props;
  const theme = useTheme();
  const { t } = useTranslationMGL('Checkout');
  const isSelected = component.selectedItems.some(item => {
    const currentItem = item as ApiInsurance;
    return currentItem.id === service.id && currentItem.units[0].id === unit.id;
  });
  const metaContent =
    unit.metaInfo?.contentByMetaType && unit.metaInfo.contentByMetaType['cmsData'].content;

  return (
    <div key={service.id} className="col mb-4 optional-service">
      <div className="card h-100 overflow-hidden">
        <div className="optional-service__badge">{t(Keys.optional)}</div>
        <img
          src={unit.media?.mainImage?.small?.url || theme?.placeholderImages?.extra}
          className="card-img-top"
        />
        <div className="card-body">
          <div className="card-title optional-service__headline">
            {metaContent && metaContent['headline'] && (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize((metaContent['headline'] as unknown) as string)
                }}
              />
            )}
            {(!metaContent || !metaContent['headline']) && (
              <div dangerouslySetInnerHTML={{ __html: sanitize(service.name) }} />
            )}
          </div>
          <div className="card-text optional-service__description">
            {metaContent && metaContent['description'] && (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize((metaContent['description'] as unknown) as string)
                }}
              />
            )}
            {(!metaContent || !metaContent['description']) && (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(unit.description)
                }}
              />
            )}
          </div>
          <div className="d-flex justify-content-between flex-wrap">
            <div className="text-primary align-self-end">
              {component.itemType === ApiItemType.INSURANCE &&
              component.componentType === ApiComponentType.OPTIONAL &&
              unit.averagePricePerPerson?.finalPrice === 0 ? (
                <span className="iso__price__value">{t(Keys.percentagePriceInsuranceLabel)}</span>
              ) : (
                <Price
                  price={unit.averagePricePerPerson}
                  prefix={'+ '}
                  suffix={t(Keys.perPerson)}
                />
              )}
            </div>
            <div>
              {!isSelected && (
                <Button
                  color="secondary"
                  onClick={() => handleSelectService(component.id, unit.id)}
                >
                  {t(Keys.select)}
                </Button>
              )}
              {isSelected && (
                <Button color="primary" onClick={() => handleRemoveService(component.id, unit.id)}>
                  {t(Keys.remove)}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default OptionalServiceCard;
