import React, { Dispatch, MutableRefObject, useCallback, useEffect, useState } from 'react';
import { Element as ScrollTo } from 'react-scroll';
import { Button, Col, Row } from 'reactstrap';
import Keys from '@/Translations/generated/en/Checkout.json.keys';
import { ApiBaseData, ApiBooking, ApiTraveler, ApiTravelerType } from '@ibe/api';
import {
  appService,
  FormBuilder,
  FormRef,
  MEDIAQUERY_DEFAULTS,
  useAuth,
  useConfig,
  useMediaQuery
} from '@ibe/components';
import { FormItemsProps } from '../../Config/travellerFormConfig';
import { getFormConfig, getSalutations } from './externals';
import { scrollElementErrorTravelerBase } from './Traveler';
import { getTravelerMockData } from '../../Config/travellerFormConfig';
import useTranslationMGL from '@/Util/useTranslationMgl';
import { getTravelerMockDataSmall } from '@/Config/travellerFormConfigSmall';

interface Props {
  travelers: ApiTraveler[];
  setTravelers: Dispatch<React.SetStateAction<ApiTraveler[]>>;
  booking: ApiBooking | null;
  formRefs: MutableRefObject<FormRef<Record<string, unknown>>[]>;
  externalData: {
    COUNTRIES: ApiBaseData[];
    SALUTATIONS: ApiBaseData[];
  };
  initialValues?: Array<{ [key: string]: string }>;
}

const TravelerForms = (props: Props): JSX.Element => {
  const { travelers, setTravelers, booking, formRefs, externalData, initialValues } = props;
  const { t } = useTranslationMGL('Checkout');
  const tBuilder = useTranslationMGL('traveller-builder');
  const config = useConfig();
  const auth = useAuth();
  const params = new URLSearchParams(window?.location?.search || '');
  const lang = params?.get('lng') || appService.lang;
  const dateFormat = config.displayFormatDate[lang.replace('-', '_')];
  const isMobileView = useMediaQuery({ type: 'max', query: MEDIAQUERY_DEFAULTS.md });
  const [initialMockValues, setInitialMockValues] = useState<Record<string, unknown>[] | undefined>(
    undefined
  );

  useEffect(() => {
    if (!!initialMockValues) {
      formRefs.current.map((ref: FormRef<Record<string, unknown>>, idx: number) => {
        ref?.resetForm(initialMockValues[idx] || initialMockValues[0]);
      });
    }
  }, [initialMockValues]);

  const onFormFieldsChange = useCallback(
    (values: Record<string, string>, id: string) => {
      const formValues = values as FormItemsProps;
      const changedTraveler = travelers.find(traveler => traveler.id === id);

      if (!!changedTraveler && changedTraveler.firstName !== formValues.firstname) {
        setTravelers(value =>
          value.map(traveler => ({
            ...traveler,
            firstName: traveler.id === id ? formValues.firstname : traveler.firstName
          }))
        );
      }
      if (!!changedTraveler && changedTraveler.lastName !== formValues.lastname) {
        setTravelers(value =>
          value.map(traveler => ({
            ...traveler,
            lastName: traveler.id === id ? formValues.lastname : traveler.lastName
          }))
        );
      }
    },
    [travelers]
  );

  return (
    <div className="travellerData">
      <div className="traveler-header font-weight-bold mb-4">{t(Keys.participantData)}</div>
      {config?.traveler?.mockAvailable && (
        <div className="d-flex justify-content-end">
          <Button
            color="secondary"
            className="mb-4 mr-3"
            onClick={(): void => {
              setInitialMockValues(
                [...Array(travelers.length).keys()].map((value, index) => {
                  if (index === 0 && !auth.authState.isLoggedIn) {
                    return getTravelerMockData();
                  } else {
                    return getTravelerMockDataSmall();
                  }
                })
              );
            }}
          >
            Fill with mock data
          </Button>
        </div>
      )}
      {travelers.map((item, idx) => {
        const personIndex =
          (travelers
            .filter(localItem => localItem.type === item.type)
            .findIndex(localItem => localItem.id === item.id) || 0) + 1;
        const isFirstAdult = personIndex === 1 && item.type === ApiTravelerType.ADULT;

        return (
          <ScrollTo key={item.id} name={scrollElementErrorTravelerBase + idx}>
            <div className="mb-4 traveller--entry" key={item.id}>
              <div className="form-module__headline">
                <Row>
                  <Col lg={12}>
                    <div>
                      {idx + 1}&nbsp;
                      {isFirstAdult && !auth.authState.isLoggedIn ? (
                        <span className="form-module__headline--suffix">{t(Keys.applicant)}</span>
                      ) : (
                        <span className="form-module__headline--suffix">{t(Keys.participant)}</span>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <FormBuilder
                key={item.id}
                formConfig={getFormConfig(
                  idx === 0 && !auth.authState.isLoggedIn,
                  item.type,
                  tBuilder.t,
                  !!initialValues ? initialValues[idx] : undefined,
                  dateFormat,
                  isMobileView
                )}
                onFormFieldsChange={(values: Record<string, string>) =>
                  onFormFieldsChange(values, item.id)
                }
                // eslint-disable-next-line
                // @ts-ignore
                ref={(ref: FormRef<Record<string, unknown>> | null): void => {
                  if (ref) {
                    formRefs.current[idx] = ref;
                  }
                }}
                hideSubmitButton
                scrollToError
                initialValues={{ id: item.id }}
                externalData={{
                  countries: externalData.COUNTRIES,
                  salutations: getSalutations(externalData.SALUTATIONS, item.type),
                  yupContext: {
                    adultMinAge: config.traveler.maxChildAge + 1,
                    childMinAge: config.traveler.maxInfantAge + 1,
                    startDate: booking?.travelStartDate
                  }
                }}
                mode="onChange"
                reValidateMode="onChange"
              >
                <div />
                <div />
              </FormBuilder>
              <Row>
                <Col md={12} className="d-flex justify-content-end">
                  <div className="form-module__lower-label">{t(Keys.requiredFields)}</div>
                </Col>
              </Row>
            </div>
          </ScrollTo>
        );
      })}
    </div>
  );
};

export default TravelerForms;
