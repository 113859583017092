/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-09-03 18:09:07 */

const Keys = {
  from: 'from',
  to: 'to',
  night: 'night',
  night_plural: 'night_plural',
  week: 'week',
  week_plural: 'week_plural',
  nightComma: 'nightComma',
  nightComma_plural: 'nightComma_plural',
  nights: 'nights',
  nights_plural: 'nights_plural',
  weekComma: 'weekComma',
  weekComma_plural: 'weekComma_plural',
  weeks: 'weeks',
  weeks_plural: 'weeks_plural',
  chooseDuration: 'chooseDuration',
  chooseRangeLabel: 'chooseRangeLabel',
  durationPlaceholder: 'durationPlaceholder'
}; export default Keys;