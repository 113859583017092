/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-09-03 18:09:07 */

const Keys = {
  firstName: { label: 'firstName.label', placeholder: 'firstName.placeholder' },
  lastName: { label: 'lastName.label', placeholder: 'lastName.placeholder' },
  email: { label: 'email.label', placeholder: 'email.placeholder' },
  requiredField: 'requiredField',
  emailError: 'emailError',
  termsAndConditionsError: 'termsAndConditionsError',
  signUp: 'signUp'
}; export default Keys;