/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-09-03 18:09:07 */

const Keys = {
  BACK_TO_LIST: 'BACK_TO_LIST',
  emptyResultsWarning: 'emptyResultsWarning',
  emptyFilteringResultsWarning: 'emptyFilteringResultsWarning',
  apiNotAbleToGetPackages: 'apiNotAbleToGetPackages',
  apiNotAbleToAddPackageToBasket: 'apiNotAbleToAddPackageToBasket',
  emptyFlightSelectionResultsWarning: 'emptyFlightSelectionResultsWarning',
  emptyRoomSelectionResultsWarning: 'emptyRoomSelectionResultsWarning',
  adults: 'adults',
  children: 'children',
  change: 'change',
  nights: 'nights',
  night: 'night',
  night_plural: 'night_plural',
  datesAndPrices: 'datesAndPrices',
  choose: 'choose',
  perPerson: 'perPerson',
  roomOnly: 'roomOnly',
  with: 'with',
  selectFlight: 'selectFlight',
  showFares: 'showFares',
  hideFares: 'hideFares',
  selectFare: 'selectFare',
  continueFare: 'continueFare',
  priceLabel: 'priceLabel',
  totalPriceLabel: 'totalPriceLabel',
  totalPriceDetailsLabel: 'totalPriceDetailsLabel',
  inAdditionToPackagePrice: 'inAdditionToPackagePrice',
  nonStop: 'nonStop',
  stop: 'stop',
  stop_plural: 'stop_plural',
  economy: 'economy',
  premiumEconomy: 'premiumEconomy',
  business: 'business',
  first: 'first',
  moreInformation: 'moreInformation',
  lessInformation: 'lessInformation',
  outboundFlight: 'outboundFlight',
  returnFlight: 'returnFlight',
  travelTime: 'travelTime',
  arrival: 'arrival',
  stopover: 'stopover',
  flightFeaturesAndAllowances: 'flightFeaturesAndAllowances',
  checkedBaggageAllowance: 'checkedBaggageAllowance',
  cabinBaggageAllowance: 'cabinBaggageAllowance',
  kilo: 'kilo',
  piece: 'piece',
  freeBaggageIncluded: 'freeBaggageIncluded',
  freeBaggageIncluded_plural: 'freeBaggageIncluded_plural',
  operatedBy: 'operatedBy',
  EXTERNAL_FLIGHT_MODIFICATIONPENALTIES_BEFORE_DEPARTURE_TRUE: 'EXTERNAL_FLIGHT_MODIFICATIONPENALTIES_BEFORE_DEPARTURE_TRUE',
  EXTERNAL_FLIGHT_MODIFICATIONPENALTIES_BEFORE_DEPARTURE_FALSE: 'EXTERNAL_FLIGHT_MODIFICATIONPENALTIES_BEFORE_DEPARTURE_FALSE',
  EXTERNAL_FLIGHT_MODIFICATIONPENALTIES_AFTER_DEPARTURE_TRUE: 'EXTERNAL_FLIGHT_MODIFICATIONPENALTIES_AFTER_DEPARTURE_TRUE',
  EXTERNAL_FLIGHT_MODIFICATIONPENALTIES_AFTER_DEPARTURE_FALSE: 'EXTERNAL_FLIGHT_MODIFICATIONPENALTIES_AFTER_DEPARTURE_FALSE',
  EXTERNAL_FLIGHT_CANCELLATIONPENALTIES_BEFORE_DEPARTURE_TRUE: 'EXTERNAL_FLIGHT_CANCELLATIONPENALTIES_BEFORE_DEPARTURE_TRUE',
  EXTERNAL_FLIGHT_CANCELLATIONPENALTIES_BEFORE_DEPARTURE_FALSE: 'EXTERNAL_FLIGHT_CANCELLATIONPENALTIES_BEFORE_DEPARTURE_FALSE',
  EXTERNAL_FLIGHT_CANCELLATIONPENALTIES_AFTER_DEPARTURE_TRUE: 'EXTERNAL_FLIGHT_CANCELLATIONPENALTIES_AFTER_DEPARTURE_TRUE',
  EXTERNAL_FLIGHT_CANCELLATIONPENALTIES_AFTER_DEPARTURE_FALSE: 'EXTERNAL_FLIGHT_CANCELLATIONPENALTIES_AFTER_DEPARTURE_FALSE',
  EXTERNAL_FLIGHT_SEAT_SELECTION_FREE: 'EXTERNAL_FLIGHT_SEAT_SELECTION_FREE',
  EXTERNAL_FLIGHT_SEAT_SELECTION_CHARGE: 'EXTERNAL_FLIGHT_SEAT_SELECTION_CHARGE',
  EXTERNAL_FLIGHT_SEAT_SELECTION_NOT_ALLOWED: 'EXTERNAL_FLIGHT_SEAT_SELECTION_NOT_ALLOWED',
  UP_TO: 'UP_TO',
  PER_ADULT: 'PER_ADULT',
  pricePerDay: 'pricePerDay',
  pricePerPassenger: 'pricePerPassenger',
  totalPrice: 'totalPrice',
  from: 'from',
  taxText: 'taxText',
  perAdult: 'perAdult',
  perPersonFull: 'perPersonFull',
  perUnit: 'perUnit',
  person: 'person',
  person_plural: 'person_plural',
  modify: 'modify',
  dateAndPrices: 'dateAndPrices',
  allAirports: 'allAirports',
  travelDate: 'travelDate',
  month: 'month',
  dayOfDeparture: 'dayOfDeparture',
  departureAirport: 'departureAirport',
  yourFlight: 'yourFlight',
  yourRooms: 'yourRooms',
  chooseYourFlights: 'chooseYourFlights',
  chooseYourRoom: 'chooseYourRoom',
  room: 'room',
  spinnerText: 'spinnerText',
  included: 'included',
  luggageIncluded: 'luggageIncluded',
  cabinLuggageIncluded: 'cabinLuggageIncluded',
  luggageNotIncluded: 'luggageNotIncluded',
  noBaggageIncludedModalHeader: 'noBaggageIncludedModalHeader',
  noBaggageIncludedModalBody: 'noBaggageIncludedModalBody',
  noBaggageIncludedModalButton: 'noBaggageIncludedModalButton',
  baggageIncludedModalHeader: 'baggageIncludedModalHeader',
  baggageIncludedModalBody: 'baggageIncludedModalBody',
  baggageIncludedModalButton: 'baggageIncludedModalButton'
}; export default Keys;