import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPercentage, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Keys from '@/Translations/generated/en/Checkout-B2B.json.keys';
import useTranslation from '@/Util/useTranslation';
import { Button, Input, Alert } from 'reactstrap';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Price } from '@ibe/components';
import { ApiPrice } from '@ibe/api';
import { validPromotionCodeStorage } from '@/Util/globals';
import { LoggerFactory, PriceFactory } from '@ibe/services';
import CheckoutStore from '../checkout/CheckoutStore';

const logger = LoggerFactory.get('ErrorBoundary');
type PromotionErrorType = 'NEW_CODE_IS_NOT_VALID' | 'NEW_CODE_IS_NOT_VALID_BUT_THE_OLD_ONE_IST';

const Promotion = ({ store }: { store: CheckoutStore }) => {
  const { t } = useTranslation('Checkout-B2B');
  const {
    inputRef,
    code,
    setCode,
    handleKeyDownSubmit,
    handleClearClick,
    submit,
    hasError,
    validPromotionCode,
    setHasError,
    discount,
    currencyCode,
    isRemovalState
  } = usePromotionCode(store);

  return (
    <>
      <div className="bestprice-price-details__divider"></div>
      <div className="bestprice-price-details__promotion">
        <div className="bestprice-price-details__content__container">
          <div className="bestprice-price-details__icon">
            <FontAwesomeIcon icon={faPercentage} />
          </div>
          <div className="bestprice-price-details__content">
            <div>{t(Keys.promotion.header)}</div>
          </div>
        </div>
        <div>
          <p>{t(Keys.promotion.label)}</p>
          <div className="bestprice-price-details-code__input">
            <div className="bestprice-price-details-code__input__inner">
              <Input
                innerRef={inputRef}
                value={code}
                placeholder={t(Keys.promotion.inputPlaceholder)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.code === 'Enter') handleKeyDownSubmit(e);
                }}
                disabled={isRemovalState}
              />
              <FontAwesomeIcon icon={faTimesCircle} onClick={handleClearClick} />
            </div>
            <Button
              color="primary"
              className="bestprice-price-details__breakWord"
              onClick={submit}
              disabled={!code}
            >
              {isRemovalState ? t(Keys.promotion.remove) : t(Keys.promotion.apply)}
            </Button>
          </div>
          <Alert
            className="mt-2 bestprice-price-details__breakWord"
            color={'warning'}
            fade
            isOpen={!!hasError}
            toggle={() => setHasError(null)}
          >
            {hasError === 'NEW_CODE_IS_NOT_VALID_BUT_THE_OLD_ONE_IST'
              ? t(Keys.promotion.newCodeIsNotValidButTheOldOneWorks, {
                  oldCode: validPromotionCode?.code.toUpperCase()
                })
              : t(Keys.promotion.codeIsNotValid)}
          </Alert>
        </div>
      </div>
      <div className="bestprice-price-details__divider"></div>
      {discount ? (
        <div className="bestprice-price-details__content__container bestprice-price-details__promotion__wrapper">
          <span>{t(Keys.promotion.priceReduction)}</span>
          {currencyCode && (
            <Price
              className="bestprice-price-details__promotion__price"
              price={PriceFactory.create(discount, currencyCode)}
              prefix={'-'}
              removeZeroDecimals={false}
              displayInline
            />
          )}
        </div>
      ) : null}
    </>
  );
};

const usePromotionCode = (store: CheckoutStore) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [code, setCode] = useState(validPromotionCodeStorage?.get()?.code || '');
  const [validPromotionCode, setValidPromotionCode] = useState(validPromotionCodeStorage.get());
  const [hasError, setHasError] = useState<PromotionErrorType | null>(null);
  const [isRemovalState, setIsRemovalState] = useState(!!validPromotionCodeStorage?.get()?.code);
  const submit = async () => {
    if (isRemovalState) {
      return await removePromotionCode();
    }
    await applyPromotionCode(code);
  };

  const handleClearClick = async () => {
    if (!isRemovalState) {
      setCode('');
    } else {
      return await removePromotionCode();
    }
  };

  const handleKeyDownSubmit = (e: React.KeyboardEvent) => {
    e.preventDefault();
    submit();
  };
  const discount = useMemo(() => getTotalDiscount(store.booking?.totalDiscount), [
    store.booking?.totalDiscount
  ]);

  useEffect(() => {
    setIsRemovalState(!!getTotalDiscount(store.booking?.totalDiscount));
  }, [store.booking?.totalDiscount]);

  const applyPromotionCode = async (code: string): Promise<void> => {
    try {
      setHasError(null);
      await store.addPromoCodesToBooking([code]);

      const oldValidCodeType = validPromotionCodeStorage.get();
      const newDiscount = getTotalDiscount(store.booking?.totalDiscount);
      if (newDiscount !== undefined && store.booking?.promoCodes.includes(code)) {
        const codeType = { code, absoluteValue: newDiscount, sign: '€' };
        validPromotionCodeStorage.set({ ...codeType });
        setValidPromotionCode({ ...codeType });
      } else {
        if (code && newDiscount === undefined) {
          return setHasError('NEW_CODE_IS_NOT_VALID');
        }
        if (oldValidCodeType && newDiscount !== undefined) {
          setHasError('NEW_CODE_IS_NOT_VALID_BUT_THE_OLD_ONE_IST');
          setValidPromotionCode(oldValidCodeType);
          setCode(oldValidCodeType.code);
        }
      }
    } catch (err) {
      logger.error(err);
    }
  };

  const removePromotionCode = async (): Promise<void> => {
    try {
      setHasError(null);
      await store.addPromoCodesToBooking([]);
      const newDiscount = getTotalDiscount(store.booking?.totalDiscount);

      if (newDiscount === undefined) {
        validPromotionCodeStorage.clear();
        setCode('');
        setValidPromotionCode(undefined);
        setIsRemovalState(false);
      } else {
        throw 'Promotion could not be remove';
      }
    } catch (err) {
      logger.error(err);
    }
  };
  const currencyCode = useMemo(() => store.booking?.price.currencyCode, [
    store.booking?.travelers,
    store?.booking?.bookedItems
  ]);
  return {
    inputRef,
    code,
    setCode,
    handleKeyDownSubmit,
    submit,
    handleClearClick,
    validPromotionCode,
    hasError,
    setHasError,
    discount,
    currencyCode,
    isRemovalState
  };
};

export default Promotion;

export const getTotalDiscount = (discount?: ApiPrice | null): number | undefined => {
  if (discount) {
    return discount.finalPrice;
  }
  return undefined;
};
